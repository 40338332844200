<template>
    <div class="lg:text-lg break-normal text-justify">
        If you would like to contribute recordings for a language that is not
        included yet, please contact:
        <p class="text-center my-2">
            <a class="style-link" href="mailto:ruil-contact@unimelb.edu.au"
                >RUIL-contact@unimelb.edu.au</a
            >
        </p>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>
