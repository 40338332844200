<template>
    <div class="text-red-800">
        <div style="font-size: 2px; visibility: hidden">
            Australian Indigenous languages, Aboriginal languages, First Nations languages,
            Australian languages.
        </div>
        <router-view></router-view>
    </div>
</template>

<script setup></script>
